import * as R from 'ramda'
import { call, delay, put, select } from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'
import { ApiError } from '@pbt/pbt-ui-components'

import ErrorTypes from '~/constants/apiErrorTypes'
import { CustomErrorCodes } from '~/constants/customErrorCodes'
import DialogNames from '~/constants/DialogNames'
import i18n from '~/locales/i18n'
import { auth0Enabled } from '~/utils'
import {
  detectAPIErrorType,
  getErrorMessage,
  getIsFinalizedError,
  NetworkErrorMessage,
} from '~/utils/errors'

import { refreshToken } from '../../actions/auth'
import { updateInvoices } from '../../actions/finance'
import { updateSoapFinalizedStatus } from '../../actions/soap'
import {
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_SUCCESS,
} from '../../actions/types/auth'
import { isDialogOpen, openDialog } from '../../duck/dialogs'
import { notifyNetworkError, registerWarnAlert } from '../../duck/uiAlerts'
import { getCurrentBusinessId, getRefreshToken } from '../../reducers/auth'
import { waitForSubsequentCall } from './index'

const RETRY_TIMEOUT = 500
const RETRY_COUNT = 3
const RETRYABLE_HTTP_METHODS = ['GET', 'POST', 'PUT']
const RETRYABLE_MESSAGES = [NetworkErrorMessage]

const EXPANDED_UNAUTHORIZED_ERROR_CODES = [
  CustomErrorCodes.WORKING_OUTSIDE_PRACTICE_HOURS,
]

type RetryOptions = {
  count: number
  messages: string[]
  methods: string[]
  timeout: number
}

// eslint-disable-next-line consistent-return
function* requestWithRetries(
  retryOptions: RetryOptions,
  method: (...args: any) => any,
  ...params: any[]
): ReturnType<typeof method> {
  const { count, timeout, methods, messages } = retryOptions
  for (let i = 0; i <= count; i++) {
    try {
      return yield call(method, ...params)
    } catch (error) {
      const err = error as ApiError
      const isPureUnathorizedError =
        err.status === 401 &&
        !EXPANDED_UNAUTHORIZED_ERROR_CODES.includes(
          err.responseBody?.descriptionId,
        )

      if (!auth0Enabled && isPureUnathorizedError) {
        const token: string = yield select(getRefreshToken)
        if (token) {
          yield waitForSubsequentCall({
            action: refreshToken(token),
            successType: REFRESH_TOKEN_SUCCESS,
            failureType: REFRESH_TOKEN_FAILURE,
          })
          return yield requestWithRetries(retryOptions, method, ...params)
        }
      }

      const matchesHttpMethod = R.includes(
        err.method?.toUpperCase() || '',
        methods,
      )
      const matchesErrorMessage = R.includes(err.originalMessage, messages)
      const hasNotExhaustedRetryLimit = i < count

      if (
        matchesHttpMethod &&
        matchesErrorMessage &&
        hasNotExhaustedRetryLimit
      ) {
        yield delay(timeout)
      } else {
        throw err
      }
    }
  }
}

function* handleAPIErrorResponses(error: ApiError) {
  const responseBody = error?.responseBody
  const errorCode = error?.status
  const errorType = detectAPIErrorType(responseBody)

  if (errorCode === 404) {
    const isAlreadyOpen: boolean = yield select(
      isDialogOpen(DialogNames.MISSING_RESOURCE),
    )
    if (!isAlreadyOpen) {
      yield put(
        openDialog({
          name: DialogNames.MISSING_RESOURCE,
          id: uuid(),
          props: {
            error,
            customMessage:
              errorType === ErrorTypes.SOAP_IS_NOT_FOUND
                ? getErrorMessage(error)
                : undefined,
          },
          unique: true,
        }),
      )
    }
  }

  if (errorType === ErrorTypes.NO_PERMISSION) {
    const isAlreadyOpen: boolean = yield select(
      isDialogOpen(DialogNames.MISSING_RESOURCE),
    )
    if (!isAlreadyOpen) {
      yield put(
        openDialog({
          name: DialogNames.MISSING_RESOURCE,
          id: uuid(),
          props: {
            error,
            customMessage: i18n.t(
              'Errors:API_ERROR.BUSINESS_HAS_NO_ACCESS_TO_RESOURCE',
            ),
          },
          unique: true,
        }),
      )
    }
  }

  if (errorType === ErrorTypes.BUSINESS_ROLES_MISSING) {
    const isAlreadyOpen: boolean = yield select(
      isDialogOpen(DialogNames.LOST_ACCESS_TO_CLINIC_ALERT_DIALOG),
    )
    if (!isAlreadyOpen) {
      yield put(
        openDialog({
          name: DialogNames.LOST_ACCESS_TO_CLINIC_ALERT_DIALOG,
          id: uuid(),
          unique: true,
        }),
      )
    }
  }

  if (errorType === ErrorTypes.APPOINTMENT_TYPE_NOT_AVAILABLE) {
    const errorObject = { responseBody: error?.responseBody?.error }
    yield notifyNetworkError(errorObject as ApiError, true)
  }

  if (errorType === ErrorTypes.ENTITY_IS_READ_ONLY) {
    const responseError = responseBody?.error
    if (responseBody?.soap) {
      yield put(updateSoapFinalizedStatus(responseBody.soap))
    }
    if (responseBody?.invoice) {
      yield put(
        updateInvoices({ [responseBody.invoice.id]: responseBody.invoice }),
      )
    }
    yield put(
      openDialog({
        name: DialogNames.SOAP_FINALIZED_ALERT,
        id: uuid(),
        props: { error: responseError },
        unique: true,
      }),
    )
  }

  if (getIsFinalizedError(errorType)) {
    yield put(
      registerWarnAlert(
        responseBody.error?.message ||
          i18n.t('Errors:API_ERROR.SOAP_IS_FINALIZED'),
      ),
    )
  }

  if (errorType === ErrorTypes.INVENTORY_LOG_IS_READ_ONLY) {
    yield put(
      registerWarnAlert(
        responseBody.error?.message ||
          i18n.t('Errors:API_ERROR.CANNOT_MODIFY_READONLY'),
      ),
    )
  }

  const communicationUserFriendlyErrorTypes = [
    ErrorTypes.EMAIL_PAYLOAD_LIMIT_ERROR_TYPE,
    ErrorTypes.BOOP_PAYLOAD_LIMIT_ERROR_TYPE,
    ErrorTypes.SMS_MESSAGE_LENGTH_LIMIT_ERROR_TYPE,
    ErrorTypes.SMS_INVALID_PHONE_NUMBER,
    ErrorTypes.SMS_RECIPIENT_REGION_IS_NOT_SUPPORTED_ERROR,
    ErrorTypes.SMS_ATTEMPT_TO_SEND_TO_UNSUBSCRIBED_RECIPIENT_ERROR,
    ErrorTypes.CONVERSATION_ACCESS_FROM_OUTER_BUSINESS_ERROR_TYPE,
  ]

  if (R.includes(errorType, communicationUserFriendlyErrorTypes)) {
    yield notifyNetworkError({ responseBody: responseBody?.error } as ApiError)
  }

  throw error
}

export default function* requestAPI<P, T extends P[]>(
  method: (businessId: string, ...args: T) => any,
  ...params: T
): ReturnType<typeof method> {
  const currentBusinessId: string = yield select(getCurrentBusinessId)
  const retryOptions = {
    count: RETRY_COUNT,
    timeout: RETRY_TIMEOUT,
    methods: RETRYABLE_HTTP_METHODS,
    messages: RETRYABLE_MESSAGES,
  }

  try {
    return yield requestWithRetries(
      retryOptions,
      method,
      currentBusinessId,
      ...params,
    )
  } catch (error) {
    return yield handleAPIErrorResponses(error as ApiError)
  }
}
