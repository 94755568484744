import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'

import { PracticeDetailsPanels } from '~/components/dashboard/admin/general/practices/practices'
import type {
  PracticeAlertConfigurationFields,
  PracticeAppointmentCommunicationFields,
  PracticeAppointmentConfigurationFields,
  PracticeAutoReplyCommunicationsFields,
  PracticeBoopCommunicationsConfigurationFields,
  PracticeBrandingConfigurationFields,
  PracticeChewyGiftCardFields,
  PracticeCountrySectionFields,
  PracticeDiscountConfigurationFields,
  PracticeDiscountReasonConfigurationFields,
  PracticeGeneralInformationFields,
  PracticeGroupDetailsFields,
  PracticeInvoiceEstimateConfigurationFields,
  PracticeKioskConfigurationFields,
  PracticeLabelConfigurationFields,
  PracticeLicensesFields,
  PracticeLocalizationFields,
  PracticeMainFields,
  PracticeMarketplaceManagementFields,
  PracticeMobileFields,
  PracticeNPSSystemFields,
  PracticeRecordSharingFields,
  PracticeRemindersSettingsFields,
  PracticeSavedSignatureConfigurationFields,
  PracticeSharingFields,
  PracticeSoapConfigurationFields,
  PracticeSocialMediaConfigurationFields,
  PracticeTaxConfigurationFields,
  PracticeTelehealthIntegrationFields,
  PracticeUnitsOfMeasurement,
  PracticeVaccineCertificateFootersFields,
  PracticeWellnessPlanActivationFields,
} from '~/types'
import { PracticeChewyFields } from '~/types'
import { VetcoveConfig } from '~/types/entities/vetcoveConfig'
import { secondLevelMerge } from '~/utils'

import type { RootState } from '../index'

export const INITIALIZE_FIELDS_FROM_SECTION =
  'businessSettings/INITIALIZE_FIELDS_FROM_SECTION'
export const UPDATE_FIELDS_FROM_SECTION =
  'businessSettings/UPDATE_FIELDS_FROM_SECTION'

export const VALIDATE_SECTIONS = 'businessSettings/VALIDATE_SECTIONS'
export const VALIDATE_SECTION_SUCCESS =
  'businessSettings/VALIDATE_SECTION_SUCCESS'
export const VALIDATE_SECTION_FAILURE =
  'businessSettings/VALIDATE_SECTION_FAILURE'

export const initializeFieldsFromSection = <SectionFieldsType>(
  businessId: string,
  initialFields: SectionFieldsType | Record<string, any>,
  sectionName: PracticeDetailsPanels,
) => ({
  type: INITIALIZE_FIELDS_FROM_SECTION,
  businessId,
  initialFields,
  sectionName,
})
export const updateBusinessFieldsFromSection = <SectionFieldsType>(
  businessId: string,
  fields: SectionFieldsType | Record<string, any>,
  sectionName: PracticeDetailsPanels,
  hasChanged: boolean,
) => ({
  type: UPDATE_FIELDS_FROM_SECTION,
  businessId,
  fields,
  sectionName,
  hasChanged,
})

export const validateBusinessSections = (businessId: string) => ({
  type: VALIDATE_SECTIONS,
  businessId,
})
export const validateBusinessSectionSuccess = (
  businessId: string,
  sectionName: PracticeDetailsPanels,
) => ({
  type: VALIDATE_SECTION_SUCCESS,
  businessId,
  sectionName,
})
export const validateBusinessSectionFailure = (
  businessId: string,
  invalidPanel: PracticeDetailsPanels,
) => ({
  type: VALIDATE_SECTION_FAILURE,
  businessId,
  invalidPanel,
})

type BusinessSettingsSection<T> = {
  fields: T
  hasChanged: boolean
}

export type BusinessSettingsSections = {
  [PracticeDetailsPanels.MAIN]: BusinessSettingsSection<PracticeMainFields>
  [PracticeDetailsPanels.GENERAL_INFORMATION]: BusinessSettingsSection<PracticeGeneralInformationFields>
  [PracticeDetailsPanels.ALERT_CONFIGURATION]: BusinessSettingsSection<PracticeAlertConfigurationFields>
  [PracticeDetailsPanels.APPOINTMENT_COMMUNICATIONS]: BusinessSettingsSection<PracticeAppointmentCommunicationFields>
  [PracticeDetailsPanels.APPOINTMENT_CONFIGURATION]: BusinessSettingsSection<PracticeAppointmentConfigurationFields>
  [PracticeDetailsPanels.AUTO_REPLY_COMMUNICATIONS]: BusinessSettingsSection<PracticeAutoReplyCommunicationsFields>
  [PracticeDetailsPanels.BOOP_COMMUNICATIONS]: BusinessSettingsSection<PracticeBoopCommunicationsConfigurationFields>
  [PracticeDetailsPanels.BRANDING_CONFIGURATION]: BusinessSettingsSection<PracticeBrandingConfigurationFields>
  [PracticeDetailsPanels.CHEWY]: BusinessSettingsSection<PracticeChewyFields>
  [PracticeDetailsPanels.CHEWY_GIFT_CARD_CONFIGURATION]: BusinessSettingsSection<PracticeChewyGiftCardFields>
  [PracticeDetailsPanels.COUNTRY_SECTION]: BusinessSettingsSection<PracticeCountrySectionFields>
  [PracticeDetailsPanels.DEPARTMENT]: BusinessSettingsSection<unknown>
  [PracticeDetailsPanels.DISCOUNT_CONFIGURATION]: BusinessSettingsSection<PracticeDiscountConfigurationFields>
  [PracticeDetailsPanels.DISCOUNT_REASON_CONFIGURATION]: BusinessSettingsSection<PracticeDiscountReasonConfigurationFields>
  [PracticeDetailsPanels.GROUP_ACCESS_ROLES]: BusinessSettingsSection<unknown>
  [PracticeDetailsPanels.GROUP_DETAILS]: BusinessSettingsSection<PracticeGroupDetailsFields>
  [PracticeDetailsPanels.IMAGING_INTEGRATIONS]: BusinessSettingsSection<unknown>
  [PracticeDetailsPanels.INVOICE_ESTIMATE_CONFIGURATION]: BusinessSettingsSection<PracticeInvoiceEstimateConfigurationFields>
  [PracticeDetailsPanels.KIOSK]: BusinessSettingsSection<PracticeKioskConfigurationFields>
  [PracticeDetailsPanels.LAB_INTEGRATIONS]: BusinessSettingsSection<unknown>
  [PracticeDetailsPanels.LABEL_CONFIGURATION]: BusinessSettingsSection<PracticeLabelConfigurationFields>
  [PracticeDetailsPanels.LICENSES]: BusinessSettingsSection<PracticeLicensesFields>
  [PracticeDetailsPanels.LOCALIZATIONS]: BusinessSettingsSection<PracticeLocalizationFields>
  [PracticeDetailsPanels.MARKETPLACE_MANAGEMENT]: BusinessSettingsSection<PracticeMarketplaceManagementFields>
  [PracticeDetailsPanels.MIGRATION]: BusinessSettingsSection<unknown>
  [PracticeDetailsPanels.MIGRATION_V2]: BusinessSettingsSection<unknown>
  [PracticeDetailsPanels.MOBILE]: BusinessSettingsSection<PracticeMobileFields>
  [PracticeDetailsPanels.NPS_SYSTEM]: BusinessSettingsSection<PracticeNPSSystemFields>
  [PracticeDetailsPanels.RECORD_SHARING]: BusinessSettingsSection<PracticeRecordSharingFields>
  [PracticeDetailsPanels.RHAPSODY_PAY]: BusinessSettingsSection<unknown>
  [PracticeDetailsPanels.SAVED_SIGNATURE_CONFIGURATION]: BusinessSettingsSection<PracticeSavedSignatureConfigurationFields>
  [PracticeDetailsPanels.SHARING]: BusinessSettingsSection<PracticeSharingFields>
  [PracticeDetailsPanels.SOAP_CONFIGURATION]: BusinessSettingsSection<PracticeSoapConfigurationFields>
  [PracticeDetailsPanels.SOCIAL_MEDIA]: BusinessSettingsSection<PracticeSocialMediaConfigurationFields>
  [PracticeDetailsPanels.SSO_CONFIGURATION]: BusinessSettingsSection<unknown>
  [PracticeDetailsPanels.REMINDER_SET_UP]: BusinessSettingsSection<PracticeRemindersSettingsFields>
  [PracticeDetailsPanels.TAX_CONFIGURATION]: BusinessSettingsSection<PracticeTaxConfigurationFields>
  [PracticeDetailsPanels.TEAM_CONFIGURATION]: BusinessSettingsSection<unknown>
  [PracticeDetailsPanels.TELEHEALTH_INTEGRATION]: BusinessSettingsSection<PracticeTelehealthIntegrationFields>
  [PracticeDetailsPanels.UNITS_OF_MEASUREMENT]: BusinessSettingsSection<PracticeUnitsOfMeasurement>
  [PracticeDetailsPanels.VACCINE_CERTIFICATE_FOOTERS]: BusinessSettingsSection<PracticeVaccineCertificateFootersFields>
  [PracticeDetailsPanels.VETCOVE_INTEGRATION]: BusinessSettingsSection<VetcoveConfig>
  [PracticeDetailsPanels.WELLNESS_PLANS]: BusinessSettingsSection<PracticeWellnessPlanActivationFields>
}

type BusinessSettings = {
  invalidPanels: PracticeDetailsPanels[]
  isValidating: boolean | null
  sections: BusinessSettingsSections
}

type BusinessSettingsMap = Record<string, BusinessSettings>

export type BusinessSettingsState = {
  map: BusinessSettingsMap
}

export const INITIAL_STATE: BusinessSettingsState = {
  map: {},
}

export const businessSettingsReducer = (
  state = INITIAL_STATE,
  action: AnyAction,
) => {
  switch (action.type) {
    case INITIALIZE_FIELDS_FROM_SECTION:
      return {
        ...state,
        map: secondLevelMerge(state.map, {
          [action.businessId]: {
            isValidating: false,
            invalidPanels: [],
            sections: {
              ...(state.map[action.businessId]?.sections || {}),
              [action.sectionName]: {
                fields: action.initialFields,
                hasChanged: false,
              },
            },
          },
        }),
      }
    case UPDATE_FIELDS_FROM_SECTION:
      return {
        ...state,
        map: secondLevelMerge(state.map, {
          [action.businessId]: {
            isValidating: false,
            sections: {
              ...(state.map[action.businessId]?.sections || {}),
              [action.sectionName]: {
                fields: action.fields,
                hasChanged: action.hasChanged,
              },
            },
          },
        }),
      }
    case VALIDATE_SECTIONS:
      return {
        ...state,
        map: secondLevelMerge(state.map, {
          [action.businessId]: {
            isValidating: true,
          },
        }),
      }
    case VALIDATE_SECTION_SUCCESS:
      return {
        ...state,
        map: secondLevelMerge(state.map, {
          [action.businessId]: {
            isValidating: false,
            invalidPanels: R.without(
              action.sectionName,
              state.map[action.businessId]?.invalidPanels || [],
            ),
          },
        }),
      }
    case VALIDATE_SECTION_FAILURE:
      return {
        ...state,
        map: secondLevelMerge(state.map, {
          [action.businessId]: {
            isValidating: false,
            invalidPanels: R.uniq([
              ...(state.map[action.businessId]?.invalidPanels || []),
              action.invalidPanel,
            ]),
          },
        }),
      }
    default:
      return state
  }
}

export const getBusinessSettings = (state: RootState): BusinessSettingsState =>
  state.businessSettings
export const getBusinessSettingsMap = (state: RootState) =>
  getBusinessSettings(state).map
export const getBusiness = (businessId: string | undefined) =>
  createSelector(getBusinessSettingsMap, (map) =>
    businessId ? R.prop(businessId, map) : undefined,
  )

export const getSettingsHasChanged = (businessId: string | undefined) =>
  createSelector(getBusiness(businessId), (settings) => {
    const sectionHasChanged = R.pluck(
      'hasChanged',
      R.values(settings?.sections || {}),
    )
    return sectionHasChanged.some(Boolean)
  })
export const getIsValidating = (businessId: string | undefined) =>
  createSelector(
    getBusiness(businessId),
    (setting) => R.prop('isValidating', setting) || false,
  )
export const getInvalidPanels = (businessId: string | undefined) =>
  createSelector(
    getBusiness(businessId),
    (setting) => R.prop('invalidPanels', setting) || [],
  )

export const getSectionFields = <T>(
  businessId: string | undefined,
  sectionName: PracticeDetailsPanels,
) =>
  createSelector(
    getBusiness(businessId),
    (settings) => (settings?.sections[sectionName]?.fields || {}) as T,
  )
