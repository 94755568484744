import { blobToFormData } from '../utils'
import { getAPIPath, request } from './core'

export * from './addendums'
export * from './appointmentTypes'
export * from './analytics'
export * from './auth'
export * from './automaticCommunications'
export * from './availabilityRules'
export * from './benchmarking'
export * from './benchmarkingPlus'
export * from './boop'
export * from './bulkPrices'
export * from './bundles'
export * from './businesses'
export * from './chargeSheet'
export * from './chewyPets'
export * from './ckeditor'
export * from './clientPreferences'
export * from './clients'
export * from './communications'
export * from './conferencing'
export * from './constants'
export * from './contacts'
export * from './conversationMessages'
export * from './conversations'
export * from './coparents'
export * from './core'
export * from './connectedDevices'
export * from './departments'
export * from './discountGroups'
export * from './discountReasons'
export * from './documents'
export * from './documentsV2'
export * from './emailAppointment'
export * from './errors'
export * from './files'
export * from './finance'
export * from './globalInventoryCatalog'
export * from './imagingDashboard'
export * from './imagingOrders'
export * from './imagingVendorConfig'
export * from './inventory'
export * from './inventoryAdjustments'
export * from './inventoryOrders'
export * from './invoiceV3'
export * from './labelPrinting'
export * from './labOrders'
export * from './labTests'
export * from './labTestsDashboard'
export * from './labVendorConfig'
export * from './landing'
export * from './lastAppointments'
export * from './linkChewyAccount'
export * from './marketplace'
export * from './medicalHistory'
export * from './medicalHistoryCard'
export * from './members'
export * from './membershipPayments'
export * from './migration'
export * from './migrationV2'
export * from './monitors'
export * from './notifications'
export * from './onHandCatalog'
export * from './orders'
export * from './patientMemberships'
export * from './patientPreferences'
export * from './patients'
export * from './payments'
export * from './pdmpReport'
export * from './prescriptions'
export * from './prescriptionTemplates'
export * from './problem'
export * from './procedures'
export * from './questions'
export * from './refunds'
export * from './registration'
export * from './reminderProtocols'
export * from './reminderProtocolSettings'
export * from './reminders'
export * from './remindersCatalog'
export * from './reportCard'
export * from './rhapsodyPay'
export * from './roles'
export * from './scheduler'
export * from './schedulerSettings'
export * from './search'
export * from './shipmentImport'
export * from './shipmentItems'
export * from './shipments'
export * from './soap'
export * from './soapDiagnoses'
export * from './soapV2'
export * from './spaces'
export * from './supportUsers'
export * from './tasks'
export * from './timeline'
export * from './timetable'
export * from './timeTracker'
export * from './users'
export * from './vaccination'
export * from './vetcoveConfig'
export * from './vitals'
export * from './wellnessPlans'
export * from './whiteboard'

export const uploadAsset = (blob: Blob) =>
  request(
    getAPIPath('upload/commons'),
    {
      method: 'POST',
      data: blobToFormData({ blob }),
      headers: { 'Content-Type': 'multipart/form-data' },
    },
    false,
  )
