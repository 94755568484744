import { useSelector } from 'react-redux'
import { PermissionArea } from '@pbt/pbt-ui-components'

import NoPermissionPage from '~/components/common/NoPermissionPage'
import { BalancePageV2 } from '~/components/dashboard/clients/balance/BalancePageV2'
import FeatureToggle from '~/constants/featureToggle'
import { getCurrentBusiness } from '~/store/reducers/auth'
import {
  getCvcCumulusRhapsodyIntegrationEnabled,
  getFeatureToggle,
} from '~/store/reducers/constants'

import BundlesPage from '../admin/catalog/bundles/BundlesPage'
import DocumentsPage from '../admin/catalog/documents/DocumentsPage'
import InventoryBulkPricesPage from '../admin/catalog/inventory/InventoryBulkPricesPage'
import InventoryPage from '../admin/catalog/inventory/InventoryPage'
import { OrderItemsPage } from '../admin/catalog/inventory/orders-and-receives/order-items/OrderItemsPage'
import LabTestsBulkPricesPage from '../admin/catalog/lab-tests/LabTestsBulkPricesPage'
import LabTestsPage from '../admin/catalog/lab-tests/LabTestsPage'
import ProceduresPage from '../admin/catalog/procedures/ProceduresPage'
import QuestionsPage from '../admin/catalog/questions/QuestionsPage'
import ReminderProtocolGroupsPage from '../admin/catalog/reminder-protocol-groups/ReminderProtocolGroupsPage'
import AppointmentConfigurationPage from '../admin/general/appointment-configuration/AppointmentConfigurationPage'
import BenchmarkingApiAccessPage from '../admin/general/benchmarking/BenchmarkingApiAccessPage'
import ConnectedDevicesPage from '../admin/general/connected-devices/ConnectedDevicesPage'
import ContactsPage from '../admin/general/contacts/ContactsPage'
import MembersPage from '../admin/general/members/MembersPage'
import PracticesPage from '../admin/general/practices/PracticesPage'
import SoapCustomizationPage from '../admin/general/soap-customization/SoapCustomizationPage'
import SpacesPage from '../admin/general/spaces/SpacesPage'
import MarketplacePage from '../admin/marketplace/MarketplacePage'
import AvailabilityRulesPage from '../admin/scheduling/availability/AvailabilityRulesPage'
import RolesManagementPage from '../admin/support/RolesManagementPage'
import ViewInvoice from '../charge-sheet/invoice/ViewInvoice'
import ViewRefundInvoice from '../charge-sheet/invoice/ViewRefundInvoice'
import ViewChargeSheet from '../charge-sheet/ViewChargeSheet'
import ClientPage from '../clients/ClientPage'
import ClientsList from '../clients/ClientsList'
import PatientPage from '../clients/patient/PatientPage'
import CommunicationsPage from '../communications/CommunicationsPage'
import ImagingDashboardPage from '../imaging-dashboard/ImagingDashboardPage'
import BalancePage from '../invoices/BalancePage'
import LabTestsDashboardPage from '../lab-tests-dashboard/LabTestsDashboardPage'
import LandingPage from '../landing/LandingPage'
import MembershipPage from '../membership/MembershipPage'
import PDMPReport from '../pdmp/PDMPReport'
import SoapPageRedirect from '../soapV2/SoapPageRedirect'
import SoapPageV2 from '../soapV2/SoapPageV2'
import TasksDashboardPage from '../tasks-dashboard/TasksDashboardPage'
import SchedulerPage from '../timetable/scheduler/SchedulerPage'
import WhiteboardPage from '../timetable/whiteboard/WhiteboardPage'
// @ts-ignore
import WellnessPlansPage from '../wellness-plans/WellnessPlansPage'
import { ProtectedRouteProps } from './ProtectedRoute'

export type MainRoute = ProtectedRouteProps & {
  path: string | string[]
}

export const useGetMainRoutes = (): MainRoute[] => {
  const currentBusiness = useSelector(getCurrentBusiness)
  const isSsoPaymentTerminalsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SSO_PAYMENT_TERMINALS),
  )
  const isIpoM0RevampClientBillingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_REVAMP_CLIENT_BILLING_PAGE),
  )
  const isCvcCumulusRhapsodyIntegrationEnabled = useSelector(
    getCvcCumulusRhapsodyIntegrationEnabled,
  )

  return [
    {
      component: LandingPage,
      path: 'landing',
      permissions: PermissionArea.BUSINESS,
    },
    {
      component: ClientsList,
      path: 'clients',
      permissions: PermissionArea.PATIENT,
    },
    {
      component: ClientPage,
      path: 'client/*',
      permissions: PermissionArea.PATIENT,
    },
    {
      component: PatientPage,
      path: [
        'patient/:patientId/snapshots',
        'patient/:patientId/records',
        'patient/:patientId',
      ],
      permissions: PermissionArea.PATIENT,
    },
    {
      component: ProceduresPage,
      path: 'admin/catalog/procedures/*',
    },
    ...(!isCvcCumulusRhapsodyIntegrationEnabled
      ? [
          {
            component: LabTestsBulkPricesPage,
            path: '/admin/catalog/lab-tests/prices',
          },
        ]
      : []),
    {
      component: LabTestsPage,
      path: ['admin/catalog/lab-tests/:labTestId', 'admin/catalog/lab-tests'],
    },
    {
      component: ReminderProtocolGroupsPage,
      path: [
        'admin/catalog/reminder-protocols/:groupId',
        'admin/catalog/reminder-protocols',
      ],
      permissions: PermissionArea.REMINDER_PROTOCOLS,
    },
    {
      component: BundlesPage,
      path: ['admin/catalog/bundles/:bundleId', 'admin/catalog/bundles'],
      permissions: PermissionArea.SOAP,
    },
    {
      component: QuestionsPage,
      path: ['admin/catalog/questions/:questionId', 'admin/catalog/questions'],
      permissions: PermissionArea.BUSINESS,
    },
    {
      component: DocumentsPage,
      path: ['admin/catalog/documents/:documentId', 'admin/catalog/documents'],
      permissions: PermissionArea.FORM,
    },
    {
      component: MembersPage,
      path: ['admin/general/members/:memberId', 'admin/general/members'],
      permissions: PermissionArea.PERSON,
    },
    {
      component: SoapCustomizationPage,
      path: [
        '/admin/general/soap-customization',
        '/admin/general/soap-customization/:templateId',
      ],
      permissions: PermissionArea.SOAP_TEMPLATE,
    },
    {
      component: AppointmentConfigurationPage,
      path: '/admin/general/appointment-configuration',
      permissions: PermissionArea.BUSINESS_APPOINTMENT_TYPE,
    },
    ...(isSsoPaymentTerminalsEnabled && currentBusiness?.posPayEnabled
      ? [
          {
            component: ConnectedDevicesPage,
            path: '/admin/general/connected-devices',
            permissions: PermissionArea.PERSON,
          },
        ]
      : []),
    {
      component: ContactsPage,
      path: ['admin/general/contacts/:contactId', 'admin/general/contacts'],
    },
    {
      component: PracticesPage,
      path: ['admin/general/practices/:practiceId', 'admin/general/practices'],
      permissions: PermissionArea.BUSINESS,
    },
    {
      component: AvailabilityRulesPage,
      path: [
        'admin/scheduling/availability/:ruleId',
        'admin/scheduling/availability',
      ],
    },
    {
      component: SpacesPage,
      path: ['admin/general/spaces/:spaceId', 'admin/general/spaces'],
      permissions: PermissionArea.SPACE,
    },
    {
      component: BenchmarkingApiAccessPage,
      path: [
        '/admin/general/benchmarking',
        '/admin/general/benchmarking/:activationKeyId',
      ],
    },
    {
      component: MarketplacePage,
      path: [
        'admin/general/marketplace/:marketplaceItemId',
        'admin/general/marketplace',
      ],
      permissions: PermissionArea.BUSINESS,
    },
    {
      component: SoapPageV2,
      path: [
        'soap/create/:appointmentId',
        'soap/add/:appointmentId',
        'soap/:soapId',
        'soap/:soapId/tab/:tabName',
      ],
      permissions: PermissionArea.SOAP,
    },
    {
      // As some users can sometimes access the url directly, we need these urls to be enabled to make the redirects.
      component: SoapPageRedirect,
      path: ['soap/create/:appointmentId/:step', 'soap/:soapId/:step'],
      permissions: PermissionArea.SOAP,
    },

    {
      component: ViewChargeSheet,
      path: ['/chargesheet/:clientId'],
    },
    {
      component: ViewInvoice,
      path: ['/invoice/:invoiceId'],
    },
    {
      component: ViewRefundInvoice,
      path: ['/refund/:refundInvoiceId'],
    },
    {
      component: SchedulerPage,
      path: ['scheduler/:view/:appointmentId', 'scheduler/:view', 'scheduler'],
      permissions: PermissionArea.EVENT_APPOINTMENT,
    },
    {
      component: WhiteboardPage,
      path: ['whiteboard/:view', 'whiteboard'],
      permissions: PermissionArea.EVENT_APPOINTMENT,
    },
    {
      component: InventoryBulkPricesPage,
      path: '/admin/catalog/inventories/prices',
    },
    {
      component: InventoryPage,
      path: [
        'admin/catalog/inventories/:currentCatalog/:catalogItemId',
        'admin/catalog/inventories/:currentCatalog',
        'admin/catalog/inventories',
      ],
      permissions: PermissionArea.INVENTORY,
    },
    {
      component: isIpoM0RevampClientBillingEnabled
        ? BalancePageV2
        : BalancePage,
      path: [
        'balance/:clientId/invoice/create/:patientId/:eventId',
        'balance/:clientId/invoice/:invoiceId',
        'balance/:clientId/estimate/:invoiceId',
        'balance/:clientId/payment/:paymentId',
        'balance/:clientId',
      ],
    },
    {
      component: MembershipPage,
      path: ['membership/:clientId', 'membership/:clientId/:currentTable'],
    },
    {
      component: WellnessPlansPage,
      path: 'admin/catalog/wellness-plans/*',
      permissions: PermissionArea.WELLNESS_PLAN,
    },
    {
      component: PDMPReport,
      path: 'admin/catalog/pdmp-file-export',
      permissions: PermissionArea.INVENTORY,
    },
    {
      component: RolesManagementPage,
      path: ['admin/support/roles', 'admin/support/roles/:memberId'],
      permissions: PermissionArea.SUPPORT,
    },
    {
      component: LabTestsDashboardPage,
      path: ['lab-tests-dashboard/:labTestIdentifier', 'lab-tests-dashboard'],
    },
    {
      component: ImagingDashboardPage,
      path: ['imaging-dashboard/:recordId', 'imaging-dashboard'],
    },
    {
      component: CommunicationsPage,
      path: 'communications/*',
    },
    {
      component: TasksDashboardPage,
      path: ['tasks-dashboard/:taskId', 'tasks-dashboard'],
      permissions: PermissionArea.TASK,
    },
    {
      component: OrderItemsPage,
      path: ['admin/catalog/inventories/orders/:orderId'],
    },
    {
      component: NoPermissionPage,
      path: '403',
    },
  ]
}
