import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import {
  Business,
  BusinessRole,
  Nil,
  PermissionArea,
  Role,
  User,
  UserPermissions,
} from '@pbt/pbt-ui-components'

import { ALL_RIGHTS, NO_RIGHTS, READ_ONLY } from '~/constants/permissions'
import RoleName from '~/constants/roleNames'
import { getRehydratedState } from '~/utils'
import {
  getErrorMessage,
  isUserNotFound,
  isWorkingOutsidePracticeHours,
} from '~/utils/errors'
import {
  isInventoryManagementRole,
  isPracticeAdminRole,
} from '~/utils/roleUtils'
import * as TimeUtils from '~/utils/time'

import * as AuthActions from '../actions/types/auth'
import type { RootState } from '../index'
import { getBusinessesMap } from './businesses'
import { getGroupRolesMap, getRolesMap } from './roles'
import { getUsersMap } from './users'

const EMPTY_ARRAY: Role[] = []

export type AuthorizationError = {
  isUserNotFoundError: boolean
  isWorkingOutsidePracticeHoursError: boolean
  message: string | null
}

export type AuthState = {
  accessToken: string | null
  authorizationError: AuthorizationError | null
  error: string | null
  errorType: string
  expiresIn: number | null
  idToken: string | null
  isFetching: boolean
  isPasswordUpdating: boolean
  isResetEmailSending: boolean
  isResettingPassword: boolean
  isSilentlyFetching: boolean
  logOutTime: number | null
  refreshToken: string | null
  silentLoginError: string | null
  silentLoginHappened: boolean
  userId: string | null
}

export const AUTH_INITIAL_STATE: AuthState = {
  isResettingPassword: false,
  isResetEmailSending: false,
  isPasswordUpdating: false,
  isFetching: false,
  error: null,
  authorizationError: null,
  errorType: '',
  accessToken: null,
  refreshToken: null,
  expiresIn: null,
  idToken: null,
  userId: null,
  silentLoginError: null,
  isSilentlyFetching: false,
  logOutTime: null,
  silentLoginHappened: false,
}

const auth = (
  state: AuthState = AUTH_INITIAL_STATE,
  action: AnyAction,
): AuthState => {
  state = getRehydratedState(AUTH_INITIAL_STATE, state)
  switch (action.type) {
    case AuthActions.RESET_PASSWORD:
      return { ...state, error: null, isResettingPassword: true }
    case AuthActions.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isResettingPassword: false,
      }
    case AuthActions.RESET_PASSWORD_SUCCESS:
      return { ...state, isResettingPassword: false }
    case AuthActions.SEND_RESET_PASSWORD_EMAIL:
      return {
        ...state,
        isFetching: true,
        error: null,
        isResetEmailSending: true,
      }
    case AuthActions.SEND_RESET_PASSWORD_EMAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: getErrorMessage(action.error),
        isResetEmailSending: false,
      }
    case AuthActions.SEND_RESET_PASSWORD_EMAIL_SUCCESS:
      return { ...state, isFetching: false, isResetEmailSending: false }
    case AuthActions.UPDATE_PASSWORD:
      return {
        ...state,
        error: null,
        isFetching: true,
        isPasswordUpdating: true,
      }
    case AuthActions.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: getErrorMessage(action.error),
        isPasswordUpdating: false,
      }
    case AuthActions.UPDATE_PASSWORD_SUCCESS:
      return { ...state, isFetching: false, isPasswordUpdating: false }
    case AuthActions.LOGIN_REQUEST:
      return { ...AUTH_INITIAL_STATE, error: null, isFetching: true }
    case AuthActions.LOGIN_FAILURE:
      return {
        ...AUTH_INITIAL_STATE,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case AuthActions.LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.tokenHolder,
        userId: action.personId,
        authorizationError: null,
      }
    case AuthActions.REFRESH_TOKEN:
      return { ...state, authorizationError: null, isFetching: true }
    case AuthActions.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        ...action.tokenHolder,
        isFetching: false,
        authorizationError: null,
      }
    case AuthActions.REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        isFetching: false,
        authorizationError: {
          message: getErrorMessage(action.error),
          isWorkingOutsidePracticeHoursError: isWorkingOutsidePracticeHours(
            action.error,
          ),
          isUserNotFoundError: isUserNotFound(action.error),
        },
      }
    case AuthActions.SET_AUTHORIZATION_ERROR:
      return {
        ...state,
        authorizationError: {
          message: getErrorMessage(action.error),
          isWorkingOutsidePracticeHoursError: isWorkingOutsidePracticeHours(
            action.error,
          ),
          isUserNotFoundError: isUserNotFound(action.error),
        },
      }
    case AuthActions.CLEAR_AUTHORIZATION_ERROR:
      return { ...state, authorizationError: null }
    case AuthActions.FETCH_CURRENT_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: getErrorMessage(action.error),
        errorType: action.type,
      }
    case AuthActions.FETCH_CURRENT_USER_SUCCESS:
      return { ...state, isFetching: false, userId: action.userId }
    case AuthActions.FETCH_CURRENT_USER:
      return { ...state, error: null, isFetching: true }
    case AuthActions.SILENT_LOGIN_REQUEST:
      return { ...state, isSilentlyFetching: true, silentLoginError: null }
    case AuthActions.SILENT_LOGIN_FAILURE:
      return {
        ...state,
        isSilentlyFetching: false,
        silentLoginError: getErrorMessage(action.error),
      }
    case AuthActions.SILENT_LOGIN_SUCCESS:
      return {
        ...state,
        isSilentlyFetching: false,
        ...action.tokenHolder,
        userId: action.personId,
        authorizationError: null,
        silentLoginHappened: true,
      }
    case AuthActions.INVALIDATE_TOKEN:
      return { ...state, accessToken: 'invalid', refreshToken: null }
    case AuthActions.SET_LOG_OUT_TIME:
      return { ...state, logOutTime: action.timeout }
    case AuthActions.CLEAR_LOG_OUT_TIME:
      return { ...state, logOutTime: null }
    case AuthActions.LOGOUT:
      return { ...state, silentLoginHappened: false }
    default:
      return state
  }
}

export default auth
export const getAuth = (state: RootState): AuthState => state.auth
export const getAuthIsResetEmailSending = (state: RootState) =>
  getAuth(state).isResetEmailSending
export const getAuthIsFetching = (state: RootState) => getAuth(state).isFetching
export const getAuthIsResettingPassword = (state: RootState) =>
  getAuth(state).isResettingPassword
export const getAuthIsPasswordUpdating = (state: RootState) =>
  getAuth(state).isPasswordUpdating
export const getAuthError = (state: RootState) => getAuth(state).error
export const getAuthErrorType = (state: RootState) => getAuth(state).errorType
export const getAuthorizationError = (state: RootState) =>
  getAuth(state).authorizationError
export const getHasAuthorizationError = (state: RootState) =>
  Boolean(getAuthorizationError(state))
export const getIsWorkingOutsidePracticeHoursError = createSelector(
  getAuthorizationError,
  (error) => error?.isWorkingOutsidePracticeHoursError,
)
export const getIsUserNotFoundError = createSelector(
  getAuthorizationError,
  (error) => error?.isUserNotFoundError,
)

export const getAuthSilentLoginError = (state: RootState) =>
  getAuth(state).silentLoginError
export const getAuthIsSilentlyFetching = (state: RootState) =>
  getAuth(state).isSilentlyFetching
export const getCurrentUserId = (state: RootState) => getAuth(state).userId
export const getCurrentUser = createSelector(
  getCurrentUserId,
  getUsersMap,
  (id, map) => map[id!],
)
export const getAccessToken = (state: RootState) => getAuth(state).accessToken
export const getRefreshToken = (state: RootState) => getAuth(state).refreshToken
export const getIsAuthenticated = (state: RootState) =>
  Boolean(getAccessToken(state))
export const getLogOutTime = (state: RootState) => getAuth(state).logOutTime
export const getCurrentBusinessId = (state: RootState) =>
  getCurrentUser(state)?.currentBusinessId
export const getCurrentBusinessRootId = (state: RootState) =>
  getCurrentUser(state)?.currentBusinessRootId
export const getCurrentHierarchyBusinessIds = (state: RootState) =>
  getCurrentUser(state)?.currentHierarchyBusinessIds
export const getCurrentGroupBusinessIds = (state: RootState) =>
  getCurrentUser(state)?.currentGroupBusinessIds
export const getLocationPickerPinned = (state: RootState) =>
  getCurrentUser(state)?.locationPickerPinned
export const getCurrentBusiness = createSelector(
  getCurrentBusinessId,
  getBusinessesMap,
  (id, map) => (id ? map[id] : undefined),
)
export const getCurrentBusinessCurrencyId = (state: RootState) =>
  getCurrentBusiness(state)?.currencyId
export const getCurrentBusinessIsIdexxImagingEnabled = (state: RootState) =>
  getCurrentBusiness(state)?.idexxImagingEnabled ?? false
export const getCurrentBusinessIsDymoConnectEnabled = (state: RootState) =>
  getCurrentBusiness(state)?.dymoConnectEnabled ?? false
export const getCurrentBusinessAppointmentConfiguration = (state: RootState) =>
  getCurrentBusiness(state)?.appointmentConfiguration
export const getCurrentBusinessAppointmentCommunicationsConfiguration = (
  state: RootState,
) => getCurrentBusiness(state)?.appointmentCommunicationsConfiguration
export const getCurrentBusinessIsAutoAssignEventEnabled = (state: RootState) =>
  getCurrentBusiness(state)?.autoAssignEvent ?? false
export const getCurrentBusinessIsAppointmentCancellationReasonEnabled = (
  state: RootState,
) => getCurrentBusiness(state)?.appointmentCancellationReasonEnabled ?? false
export const getCurrentBusinessButtonsColor = (state: RootState) =>
  getCurrentBusiness(state)?.buttonsColor
export const getCurrentBusinessHasOpenBoop = (state: RootState) =>
  getCurrentBusiness(state)?.openBoop ?? false
export const getCurrentBusinessKioskEnabled = (state: RootState) =>
  getCurrentBusiness(state)?.kioskEnabled ?? false
export const getCurrentBusinessCounties = (state: RootState) =>
  getCurrentBusiness(state)?.counties
export const getCurrentBusinessWellnessPlansEnabled = (state: RootState) =>
  getCurrentBusiness(state)?.wellnessPlansEnabled ?? false
export const getCurrentBusinessHasActiveWellnessPlans = (state: RootState) =>
  getCurrentBusiness(state)?.hasActiveWellnessPlans ?? false
export const getCurrentBusinessHasWellnessPackages = (state: RootState) =>
  getCurrentBusiness(state)?.hasWPackages ?? false
export const getCurrentBusinessSendRemindersEnabled = (state: RootState) =>
  getCurrentBusiness(state)?.sendReminders ?? false
export const getSmsCommunicationsEnabled = (state: RootState) =>
  getCurrentBusiness(state)?.clientsCommunicationEnabled ?? false
export const getCurrentBusinessTimeZone = (state: RootState) =>
  getCurrentBusiness(state)?.timeZone
export const getCurrentBusinessMeasurementTypeId = (state: RootState) =>
  getCurrentBusiness(state)?.measurementTypeId
export const getCurrentBusinessTaxes = (state: RootState) =>
  getCurrentBusiness(state)?.taxes ?? []
export const getSilentLoginHappened = (state: RootState) =>
  getAuth(state).silentLoginHappened
export const getCurrentBusinessHasWaitListEnabled = (state: RootState) =>
  getCurrentBusiness(state)?.waitListEnabled ?? false
export const getCurrentBusinessCountryCatalogCode = (state: RootState) =>
  getCurrentBusiness(state)?.countryCatalogCode
export const getCurrentBusinessCIF = (state: RootState) =>
  getCurrentBusiness(state)?.taxIdentificationNumber
export const getCurrentBusinessChatEnabled = (state: RootState) =>
  Boolean(
    getCurrentBusiness(state)?.chatIntegrationCompleted &&
      getCurrentBusiness(state)?.chatUiEnabled,
  )
export const getCurrentBusinessIsOmniChannel = (state: RootState) =>
  getCurrentBusiness(state)?.omniChannel ?? false

export const getAuthSanitized = (state: RootState) => ({
  ...state.auth,
  accessToken: '*',
  refreshToken: '*',
})

const getRoles = (
  user: User | Nil,
  business: Business | Nil,
  rolesMap: Record<string, Role> | Nil,
  groupRolesMap: Record<string, string[]> | undefined,
  businessesMap: Record<string, Business> | undefined,
): Role[] => {
  if (
    !user ||
    !business ||
    !user.businessToRoleList ||
    !rolesMap ||
    !Object.keys(rolesMap).length
  ) {
    return EMPTY_ARRAY
  }

  const userHasSharedGroupRole = (businessToRole: BusinessRole) => {
    if (!businessesMap || !groupRolesMap) return false

    const currentBusiness = businessesMap[business.id]
    if (!currentBusiness || !currentBusiness.parentBusinessId) return false
    const parentBusiness = businessesMap[currentBusiness.parentBusinessId]
    if (!parentBusiness) return false
    const groupSharedRoles = groupRolesMap[parentBusiness?.id]
    if (!groupSharedRoles) return false

    // If the business group has marked this as a shared group role
    // and the user has this role in the group, the user is treated
    // as having the role in every business in the group
    if (
      groupSharedRoles.includes(businessToRole.role) &&
      parentBusiness?.id === businessToRole.business
    ) {
      return true
    }
    return false
  }

  return user.businessToRoleList
    .filter(
      (businessToRole) =>
        businessToRole.business === business.id ||
        userHasSharedGroupRole(businessToRole),
    )
    .map(({ role, business: businessId }) => ({
      ...rolesMap[role],
      businessId,
    }))
    .filter(Boolean)
}

export const getCurrentRoles = createSelector(
  [
    getCurrentUser,
    getCurrentBusiness,
    getRolesMap,
    getGroupRolesMap,
    getBusinessesMap,
  ],
  getRoles,
)

export const getCurrentGroupRoles = createSelector(
  [getCurrentUser, getRolesMap, getCurrentBusinessRootId],
  (user, rolesMap, businessRootId) => {
    if (
      !user ||
      !user.currentBusinessId ||
      !user.businessToRoleList ||
      !rolesMap ||
      !Object.keys(rolesMap).length
    ) {
      return EMPTY_ARRAY
    }

    const currentHierarchyGroupIds = R.without(
      [user.currentBusinessId],
      user.currentHierarchyBusinessIds || [],
    )

    return user.businessToRoleList
      .filter(
        ({ business: businessId }) =>
          currentHierarchyGroupIds.includes(businessId) &&
          businessId !== businessRootId,
      )
      .map(({ role, business: businessId }) => ({
        ...rolesMap[role],
        businessId,
      }))
      .filter(Boolean)
  },
)

const isPIMSRole = (role: Role) => !role?.rhapsodyAnalytics
export const getHasPIMSAccess = R.pipe(getCurrentRoles, R.any(isPIMSRole))

const getGenericCRUDForRoles =
  (area: PermissionArea | Nil) =>
  (roles: Role[]): UserPermissions =>
    roles
      .map((role) => (area ? role?.permissions?.[area] : undefined))
      .filter(Boolean)
      .reduce(R.mergeWith(R.or), NO_RIGHTS) as UserPermissions

const getPimsCRUDForRoles = (roles: Role[]): UserPermissions =>
  R.any(isPIMSRole, roles) ? READ_ONLY : NO_RIGHTS

const getCRUDForRoles = (area: PermissionArea | Nil) =>
  area === PermissionArea.PIMS
    ? getPimsCRUDForRoles
    : getGenericCRUDForRoles(area)

export const getMultipleAreaCRUDUnionForRoles = (areas: PermissionArea[]) =>
  createSelector(
    getCurrentRoles,
    (roles: Role[]) =>
      areas
        .map((area) => getCRUDForRoles(area)(roles))
        .reduce(R.mergeWith(R.or), NO_RIGHTS) as UserPermissions,
  )

export const getMultipleAreaCRUDIntersectionForRoles = (
  areas: PermissionArea[],
) =>
  createSelector(
    getCurrentRoles,
    (roles: Role[]) =>
      areas
        .map((area) => getCRUDForRoles(area)(roles))
        .reduce(R.mergeWith(R.and), ALL_RIGHTS) as UserPermissions,
  )

export const getCRUDByArea = (area: PermissionArea | Nil) =>
  createSelector(getCurrentRoles, getCRUDForRoles(area))

export const getGroupCRUDByArea = (area: PermissionArea | Nil) =>
  createSelector(getCurrentGroupRoles, getCRUDForRoles(area))

export const getCRUDByAreaForBusiness = (
  area: PermissionArea,
  business?: Business,
) =>
  createSelector(
    getCurrentUser,
    getRolesMap,
    getGroupRolesMap,
    getBusinessesMap,
    (currentUser, rolesMap, groupRolesMap, businessesMap) =>
      getCRUDForRoles(area)(
        getRoles(currentUser, business, rolesMap, groupRolesMap, businessesMap),
      ),
  )

export const createCRUDSelectorForArea = (area: PermissionArea) =>
  createSelector(
    getCurrentUser,
    getRolesMap,
    getGroupRolesMap,
    getBusinessesMap,
    (currentUser, rolesMap, groupRolesMap, businessesMap) =>
      (business: Business) =>
        getCRUDForRoles(area)(
          getRoles(
            currentUser,
            business,
            rolesMap,
            groupRolesMap,
            businessesMap,
          ),
        ),
  )

export const getAssignedLocationIds = createSelector(getCurrentUser, (user) =>
  R.uniq(R.pluck('business', user?.businessToRoleList || [])),
)

export const getAssignedLocations = createSelector(
  getCurrentUser,
  getBusinessesMap,
  (user, businessesMap) => {
    const businessIds = R.uniq(
      R.pluck('business', user?.businessToRoleList || []),
    )
    return businessIds.map((id) => businessesMap[id]).filter(Boolean)
  },
)

export const getIsPracticeAdministrator = (business: Business | Nil) =>
  createSelector(
    getCurrentUser,
    getRolesMap,
    getGroupRolesMap,
    getBusinessesMap,
    (user, rolesMap, groupRolesMap, businessesMap) => {
      const roles = getRoles(
        user,
        business,
        rolesMap,
        groupRolesMap,
        businessesMap,
      )
      return R.any(isPracticeAdminRole, roles)
    },
  )

export const getCurrentUserHasRoleByRoleName = (roleName: RoleName) =>
  createSelector(getCurrentRoles, (roles) =>
    roles.some((role) => role.name === roleName),
  )

export const hasBulkEditPermissionInCurrentBusiness = createSelector(
  getCurrentRoles,
  (roles) => {
    const isRequiredRole = R.anyPass([
      isPracticeAdminRole,
      isInventoryManagementRole,
    ])
    return R.any(isRequiredRole, roles)
  },
)

const hasUserAccessToBenchmarkKeys = (role: Role) =>
  R.includes(role.name, [RoleName.SuperAdmin])
export const getHasUserAccessToBenchmarkKeys = (business?: Business) =>
  createSelector(
    getCurrentUser,
    getRolesMap,
    getGroupRolesMap,
    getBusinessesMap,
    (user, rolesMap, groupRolesMap, businessesMap) => {
      const roles = getRoles(
        user,
        business,
        rolesMap,
        groupRolesMap,
        businessesMap,
      )
      return R.any(hasUserAccessToBenchmarkKeys, roles)
    },
  )

export const getTimeTrackerEnabled = createSelector(
  getCurrentUser,
  getCurrentBusinessId,
  (user, businessId) => TimeUtils.getTimeTrackerEnabled(user, businessId || ''),
)

export const getAllowedRolesByBusinessMap = createSelector(
  getCurrentUser,
  getRolesMap,
  (currentUser, rolesMap) =>
    currentUser?.businessToRoleList?.reduce(
      (map: Record<string, Role[]>, { business, role }) => {
        const existingAllowedIds = map[business]?.map((item) => item?.id) || []
        const newAllowedIds = rolesMap[role].allowedRolesForOtherPersonSet || []
        const uniqAllowedIds = R.uniq([...existingAllowedIds, ...newAllowedIds])

        return {
          ...map,
          [business]: uniqAllowedIds.map((id) => rolesMap[id]).filter(Boolean),
        }
      },
      {},
    ) || {},
)

export const getAllowedRoles = (state: RootState) => {
  const allowedRolesByBusiness = getAllowedRolesByBusinessMap(state)
  const currentUser = getCurrentUser(state)

  return R.pipe(
    R.map((businessId: string) => allowedRolesByBusiness[businessId]),
    R.flatten,
    R.uniq,
  )(currentUser?.currentHierarchyBusinessIds || [])
}
